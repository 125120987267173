import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import oravel_stays from './oravel_stays.jpg';
import topunlisted from './topunlisted.jpg';
import usercial from './usercial.jpg';
import sharenews1 from "../../../assets/images/sharenews1.png";
import sharenews2 from "../../../assets/images/sharenews2.png";
import sharenews3 from "../../../assets/images/sharenews3.png";
// import Link from '@mui/material/Link';
import Button from '@mui/material/Button';


export default function EndPage() {
  return (
    <div >
      <Box sx={{ textAlign: 'center', fontFamily:'Daikon-regular', padding:'12px'}}><h1>Equity Shares Blogs</h1> </Box>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem 1rem 0rem 1rem", padding:'12px' }}>
        <Card sx={{ maxWidth: 380, paddingRight:'12px' }}>
          <CardActionArea>
            <CardMedia sx={{ minHeight: 300, cursor: "default" }}
              component="img"
              height="140"
              image={sharenews2}
              alt="green iguana"
            />
            <CardContent>
              {/* <Typography variant="body2" color="text.secondary" sx={{ cursor: "text" ,fontFamily:'Daikon-regular'}}>
                08 March, 2024,
              </Typography> */}
              <Box sx={{ textDecoration: "none" }}><Typography gutterBottom variant="h5" component="div" sx={{ fontSize: "larger", "fontWeight": 600, color: "black", "&:hover": { color: "green", boxshadow: "none" },fontFamily:'Daikon-regular' }}>
              How to conduct due diligence on pre-IPO companies before investing.
              </Typography></Box>
              <Typography variant="body2" color="text.secondary" sx={{ cursor: "text", fontFamily:'Daikon-regular' }}>
              Uncovering the hidden gems: A guide to pre-IPO due diligence. Due diligence is an essential step when considering investing in pre-IPO companies. It's the process of gathering and evaluating information about a company to determine whether it's a viable investment opportunity. By conducting due diligence, investors can better understand a company's financial health, management team, and overall business prospects.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 380 }}>
          <CardActionArea>
            <CardMedia sx={{ minHeight: 300, cursor: "default" }}
              component="img"
              height="140"
              image={sharenews1}
              alt="green iguana"
            />
            <CardContent>
              {/* <Typography variant="body2" color="text.secondary" sx={{ cursor: "text", fontFamily:'Daikon-regular' }}>
                08 March, 2024
              </Typography> */}
              <Box sx={{ textDecoration: "none" }}><Typography gutterBottom variant="h5" component="div" sx={{ fontSize: "larger", "fontWeight": 600, color: "black", "&:hover": { color: "green", boxshadow: "none" }, fontFamily:'Daikon-regular' }}>
              The impact of the current economic climate on pre-IPO investing.
              </Typography></Box>
              <Typography variant="body2" color="text.secondary" sx={{ cursor: "text", fontFamily:'Daikon-regular' }}>
              The current economic climate can have a significant impact on pre-IPO investing. Economic conditions can affect a company's financial performance, ability to go public, and demand for its stock once it does. In a strong economy, companies may easily secure funding and be more likely to go public.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 380, paddingLeft:'12px' }}>
          <CardActionArea >
            <CardMedia sx={{ minHeight: 300, cursor: "default",  }}
              component="img"
              height="140"
              image={sharenews3}
              alt="green iguana"
            />
            <CardContent>
              {/* <Typography variant="body2" color="text.secondary" sx={{ cursor: "text",fontFamily:'Daikon-regular' }}>
                11 March, 2024.
              </Typography> */}
              <Box sx={{ textDecoration: "none",fontFamily:'Daikon-regular' }}><Typography gutterBottom variant="h5" component="div" sx={{ fontSize: "larger", "fontWeight": 600, color: "black", "&:hover": { color: "green", boxshadow: "none" } }}>
              Differences between pre-IPO and post-IPO investing
              </Typography></Box>
              <Typography variant="body2" color="text.secondary" sx={{ cursor: "text", fontFamily:'Daikon-regular' }}>
              Beyond the public offering: Understanding the long-term implications of pre-IPO and post-IPO investing. Pre-IPO and post-IPO investing are both ways to invest in a company, but they differ in several key ways. Pre-IPO investing refers to buying shares in a company before it goes public. 
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Box sx={{ textAlign:'center', padding:'20px'}}>
        <Button variant="contained" color='success' sx={{ backgroundColor: "#35a848" }}><Link style={{ textDecoration: 'none', color:'white' }} to='/insights'>View More</Link></Button>
      </Box>
    </div>
  );
}
