// import * as React from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import CardActionArea from "@mui/material/CardActionArea";
// import { Link } from "react-router-dom";
// import { Link as MuiLink } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

// export default function CompanyCard({
//   imageUrl,
//   name,
//   ipoYear,
//   id,
//   onClick,
//   heading,
//   url,
//   about,
//   address,
//   directorName,
//   registrationDate,
//   mail,
//   city
// }) {
//   return (
//     <Card sx={{ maxWidth: 345 }}>
//       <CardActionArea>

//         <CardMedia
//           component="img"
//           height="40"
//           width="40"
//           sx={{ objectFit: "contain" }}
//           //   image="/static/images/cards/contemplative-reptile.jpg"
//           image={imageUrl}
//           alt="green iguana"
//         />
//         <CardContent>
//           {url !== null ? (
//             <Typography variant="h6" component="div">
//               <MuiLink
//                 className="mainbodycard1"
//                 href={url}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 onClick={onClick}
//                 sx={{
//                   color: "black",
//                   fontSize: "17.5px",
//                   textDecoration: "none",
//                   "&:hover": { textDecoration: "underline" },
//                 }}
//               >
//                 <Typography gutterBottom variant="p" component="div">
//                   {name.toUpperCase()}
//                 </Typography>
//               </MuiLink>
//             </Typography>
//           ) : (
//             <Typography variant="h6" component="div">
//               <Link
//                 className="mainbodycard1"
//                 to="#"
//                 onClick={onClick}
//                 sx={{
//                   color: "#007bff",
//                   textDecoration: "none",
//                   "&:hover": { textDecoration: "underline" },
//                 }}
//               >
//                 <Typography gutterBottom variant="p" component="div">
//                   {name.toUpperCase()}
//                 </Typography>
//               </Link>
//             </Typography>
//           )}
//             <Typography>
//             <strong style={{color:'grey'}}>Director Name:</strong> {directorName}
//             </Typography>
//             <Typography>
//             <strong style={{color:'grey'}}>City:</strong> {city}
//             </Typography>

//           <Typography variant="body2" sx={{ color: "text.secondary" }}>
//             {about}
//           </Typography>
//           <Typography>
//           <FontAwesomeIcon icon={faEnvelope} />
//             <span style={{color:'#808080'}}>   {mail}  </span>

//           </Typography>

//         </CardContent>
//       </CardActionArea>
//     </Card>
//   );
// }

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";
import { Link as MuiLink, Box, Stack, Container, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function CompanyCard({
  imageUrl,
  name,
  id,
  onClick,
  url,
  about,
  directorName,
  mail,
  city,
  totalShares,
  perShareValue,
  overview,
  ipoYear,
}) {
  return (
    <Card sx={{ maxWidth: 350, borderRadius: "10px", boxShadow: 3 }}>
      <CardActionArea>
        <CardContent sx={{ padding: "16px" }}>
          {/* Container for logo and name */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              {url ? (
                <MuiLink
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={onClick}
                  sx={{
                    color: "black",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#1976d2",
                    },
                  }}
                >
                  {name.toUpperCase()}
                </MuiLink>
              ) : (
                <Link
                  to="#"
                  onClick={onClick}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {name.toUpperCase()}
                </Link>
              )}
            </Typography>

            {/* Logo on the right */}
            <CardMedia
              component="img"
              height="60"
              sx={{
                objectFit: "contain",
                width: "60px",
                marginLeft: "10px",
              }}
              image={imageUrl}
              alt="company logo"
            />
          </Box>

          {/* Director and city details */}
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <strong>Director:</strong> {directorName}
            </Typography> */}
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <FontAwesomeIcon icon={faLocationDot} /> {city}
            </Typography>
          </Stack>

          {/* About section */}
          {/* <Typography variant="body2" sx={{ mt: 2, color: "text.secondary" }}>
           
            {overview}
          </Typography> */}

          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "text.secondary",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {overview}
          </Typography>

          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                color: "text.secondary",
              }}
            >
              <Typography variant="body2">
                {" "}
                <strong>Total Shares</strong>
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: "#808080" }}>
                {totalShares}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                color: "text.secondary",
              }}
            >
              <Typography variant="body2">
                {" "}
                <strong>Per Share Value</strong>
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: "#808080" }}>
                {perShareValue}
              </Typography>
            </Box>
          </Container>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              color: "text.secondary",
            }}
          >
            <Typography variant="body2">
              IPO Year:
              <span style={{ color: "red" }}>
                &nbsp;&nbsp;&nbsp;
                {ipoYear}
              </span>
            </Typography>
            <Link to={'/company'} state={id}>
            <Button className="company-btn">Contact Company</Button>

            </Link>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
